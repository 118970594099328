
/*=============================================
=              05. Header Css            =
=============================================*/

.header-area{
    &.is-sticky{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        animation: .95s ease-in-out 0s normal none 1 running fadeInDown;
        z-index: 999;
        transition: $transition--default;
        box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: $white;

        .navigation-menu{
            &>ul{
                &>li{
                    &>a{
                        padding-top: 30px;
                        padding-bottom: 30px;
                    }
                }
            }
            &--text_white {
                & > ul{
                    & > li{
                        & > a{
                            color: #444;
                            & > span{
                                &:after{
                                    background-color: #444;
                                }
                            }
                        }
                        &.active{
                            & > a{
                                color: $black;
                            }
                        }

                        &:hover, &.active{
                            & > a{
                                &:after{
                                    color: $black;
                                }
                                & > span{
                                    color: $black;
                                }
                            }
                        }
                    }
                }
            }
        }

        .header-button{
            & > a{
                &.ht-btn{
                    padding: 0 30px;
                    height: 48px;
                    line-height: 44px;
                }
            }
            &.button--white {
                & > a{
                    padding: 0 28px;
                    height: 46px;
                    line-height: 46px;
                    &.btn{
                        color: $theme-color--default;
                        border: 2px solid  $theme-color--default;
                        &:hover {
                            transform: translateY(-3px);
                            border-color: transparent;
                            background-image: $gradient--default;
                            background-size: 200% auto;
                            background-position: left center;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        & .header{
            &__logo{
                & .dark-logo {
                    display: inherit;
                }
                & .light-logo {
                    display: none;
                }
            }
            
        }
        
        &.header-style-three {
            min-height: 80px;
            & .search-icon > a , .minicart-wrapper .minicart-icon{
                color: $theme-color--default;
            }
            & .minicart-wrapper .minicart-icon .cart_count {
                color: #fff;
                background: $theme-color--default;
            }
            & .header-button {
                & a {
                    color: $theme-color--default;
                    border: 2px solid  $theme-color--default;
                    background: transparent;
                    padding: 0 25px;
                    line-height: 48px;
                    &:hover {
                        transform: translateY(-3px);
                        border-color: transparent;
                        background-image: $gradient--default;
                        background-size: 200% auto;
                        background-position: left center;
                        color: #ffffff;
                    }
                }
            }
            & .hidden-icons-menu a {
                color: #6D70A6;
            }
        }
        & .icon-color-white {
            & .search-icon > a , .minicart-wrapper .minicart-icon{
                color: $theme-color--default;
            }
            & .minicart-wrapper .minicart-icon .cart_count {
                color: #fff;
                background: $theme-color--default;
            }
        }
        & .open-main-menu-icon, .mobile-navigation-icon  {
            &:hover {
                i {
                    background-color: $theme-color--default;
                }
            }
            i {
                background-color: $theme-color--default;;
                &:before{
                    background-color: $theme-color--default;
                }
                &:after{
                    background-color: $theme-color--default;
                }
            }
        }
        
        
        & .header__actions {
            &--four {
                & .ht-social-networks {
                    & .item {
                        & a {
                            color: $body-text-color;
                            &:hover {
                                color: $white;
                            }
                        }
                    } 
                }
            }
        }
    }
    
    &--absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 5;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            position: static;
        }
        &__inner{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 5;
        }
    }
}
.header-style-three {
    &.header-area--absolute {
        @media #{$desktop-device, $tablet-device, $large-mobile}{
            position: absolute;
        }
        &.is-sticky{
            position: fixed;
        }
    }
}

.header{
    display: flex;
    align-items: center;

    
    /* logo */
    
    &__logo{
        flex-basis: 20%;
        padding-top: 20px;
        padding-bottom: 20px;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            flex-basis: 30%;
        }

        @media #{$small-mobile}{
            flex-basis: 50%;
        }
        & > a{
            img{
                width: 180px;
            }
        }
        
        & .dark-logo {
            display: none;
        }
        & .light-logo {
            display: inherit;
        }
        @media #{$desktop-device,$tablet-device, $large-mobile}{
            & .dark-logo {
                display: inherit;
            }
            & .light-logo {
                display: none;
            }
        }
    }

    
    /* navigation */
    
    &__navigation{
        flex-grow: 1;

        .navigation-menu{
            &>ul{
                &>li{
                    &>a{
                        padding: 50px 25px;
                        @media #{$laptop-device}{
                            padding: 50px 15px;
                        }
                    }
                }
            }
        }
        
        &--four {
            flex-grow: 1;
            display: flex !important;
            align-items: center;
            justify-content: flex-end !important;
        }
    }
    
    /* header action */
    
    &__actions{
        flex-basis: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @media #{$laptop-device}{
            flex-basis: 26%;
        }

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            flex-basis: 70%;
        }

        @media #{$small-mobile}{
            flex-basis: 50%;
        }
        &--four {
            flex-basis: 15%; 
            display: flex;
            justify-content: flex-end;
            align-items: center;
            
            @media #{$laptop-device}{
                flex-basis: 20%;
            }

            @media #{$desktop-device}{
                flex-basis: 70%;
            }
            @media #{ $tablet-device, $large-mobile}{
                flex-basis: 70%;
            }

            @media #{$small-mobile}{
                flex-basis: 50%;
            }
            
            & .ht-social-networks {
                & .item {
                    & a {
                        color: $white;
                        @media #{$desktop-device, $tablet-device, $large-mobile}{
                            color: $body-text-color;
                            &:hover {
                                color: $white;
                            }
                        }
                    }
                } 
            }
            
            
        }
        &--five {
            flex-basis: 5%; 
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media #{$desktop-device}{
                flex-basis: 70%;
            }
            @media #{ $tablet-device, $large-mobile}{
                flex-basis: 70%;
            }
            @media #{$small-mobile}{
                flex-basis: 50%;
            }
            
            & .search-icon > a {
                color: #333;
            }
            
        }
    }

    &__icons-wrapper{
        display: flex;
        align-items: center;
        @media #{$large-mobile}{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            justify-content: center;
            padding: 10px;
            box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
            transform: translateY(30px);
            background-color: $white;
            visibility: hidden;
            opacity: 0;
            transition: $transition--default;
        }

        &.active{
            @media #{$large-mobile}{
                visibility: visible;
                opacity: 1;
                transform: translateY(0);
                z-index: 999;
            }
        }
    }
    
    &--style-center {
        .header__share {
            flex-basis: 20%;
            padding-top: 20px;
            padding-bottom: 20px;
            
            @media #{$desktop-device, $tablet-device}{
                flex-basis: 40%;
            }
            @media #{$large-mobile}{
                flex-basis: 35%;
            }

            @media #{$small-mobile}{
                flex-basis: 50%;
            }
            
        }
        
        @media #{$laptop-device}{
            .header__navigation .navigation-menu > ul > li > a {
                padding: 40px 10px;
            }
        }
        @media #{$large-mobile}{
            .navigation-menu {
                padding: 25px;
            }
        }
        
        .header__actions{
            flex-basis: 20%;
            .search-icon {
                padding-left: 15px;
            }
            @media #{$laptop-device}{
                flex-basis: 22%;
                & .ht-btn {
                    padding: 0 24px;
                }
                .header-button {
                    padding-left: 20px;
                }
            }

            @media #{$desktop-device, $tablet-device, $large-mobile}{
                flex-basis: 50%;
            }

            @media #{$small-mobile}{
                flex-basis: 50%;
            }
        }
    }
}

/* open main menu */

.open-main-menu-icon {
    width: 24px;
    height: 25px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-right: 30px;

    &:hover{
        i{
            background-color: $white;
            &:before{
                width: 80%;
                background-color: $white;
            }
            &:after{
                background-color: $white;
                width: 60%;
            }
        }
    }

    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: $white;
        transition: $transition--default;

        &:before{
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $white;
            content: "";
            transition: $transition--default;
        }

        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $white;
            content: "";
            transition: $transition--default;
        }
    }
}

.open-menuberger-wrapper {
    transition: $transition--default;
    background-color: #5E61E7;
    background-image: linear-gradient(138deg, #5E61E7 0%, #9C7AF2 100%);
    padding: 30px 0;
    width: 100%;
    height: 100%;
    text-align: center;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -11;
    overflow: hidden;
    overflow-y: auto;
    opacity: 0;
    transform: scale(0.3);
    
    overflow: hidden;
    overflow-y: hidden;
    
    
    &.is-visiable {
        visibility: visible;
        opacity: 1;
        transform: scale(1);
        z-index: 9999;
    }
    
    & .page-close {
        position: fixed;
        top: 80px;
        right: 90px;
        z-index: 100000;
        font-size: 100px;
        line-height: 0;
        cursor: pointer;
        height: 40px;
        width: 40px;
        transition: $transition--default;
        &::after, 
        &::before{
            position: absolute;
            top: 19px;
            left: 4px;
            content: "";
            width: 32px;
            height: 3px;
            background: $white;
            transform-origin: 50% 50%;
            transition: $transition--default;
        }
        &::before {
            transform: rotate(-45deg);
        }
        &::after {
            transform: rotate(45deg);
        }
    } 
    
    & .nav-open-menuberger {
        & li {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
            a {
                color: $white;
                font-weight: 500;
                line-height: 45px;
                font-size: 40px;
                transition: $transition--default;
                &:hover {
                    color: $theme-color--green;
                }
            }
        }
    }
    
    
}
.icon-color-white {
    & .search-icon > a , .minicart-wrapper .minicart-icon{
        color: $white;
    }
    & .minicart-wrapper .minicart-icon .cart_count {
        color: #333;
        background: #fff;
    }
}
.header-style-three{
    display: flex;
    align-items: center;
    min-height: 120px;
    & .search-icon > a , .minicart-wrapper .minicart-icon{
        color: $white;
    }
    & .minicart-wrapper .minicart-icon .cart_count {
        color: #333;
        background: #fff;
    }
    & .header-button {
        & a {
            color: #fff;
            border-color: rgba(46, 50, 128, 0.2);
            background: rgba(46, 50, 128, 0.2);
            padding: 0 30px;
            border-radius: 5px;
            line-height: 48px;
            border: 2px solid rgba(101, 105, 187, 0.2);
        }
    }
    
    @media #{$large-mobile}{
        min-height: 80px;
        & .search-icon > a , .minicart-wrapper .minicart-icon{
            color: $theme-color--default;
        }
        & .minicart-wrapper .minicart-icon .cart_count {
            color: #fff;
            background: $theme-color--default;
        }
        & .header-button {
            & a {
                color: $theme-color--default;
                border: 2px solid  $theme-color--default;
                background: transparent;
                padding: 0 25px;
                line-height: 48px;
                &:hover {
                    transform: translateY(-3px);
                    border-color: transparent;
                    background-image: $gradient--default;
                    background-size: 200% auto;
                    background-position: left center;
                    color: #ffffff;
                }
            }
        }
    }
    
    & .mobile-navigation-icon {
        margin-right: 30px;
        margin-left: 0;
        &:hover {
            i {
                background-color: $white;
            }
        }
        i {
            background-color: $white;
            &:before{
                background-color: $white;
            }
            &:after{
                background-color: $white;
            }
        }
    }
    & .hidden-icons-menu a {
        color: $white;
    }
}
/*============================== 
    - Navigation Menu Css
===============================*/

.navigation-menu{
    & > ul{
        display: flex;
        justify-content: center;
        & > li{
            & > a {
                display: block;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #ababab;
                padding: 50px 25px;
                
                & > span{
                    position: relative;
                    &:after{
                        content: '';
                        width: 0;
                        height: 1px;
                        bottom: 0;
                        position: absolute;
                        left: auto;
                        right: 0;
                        z-index: -1;
                        background-color: $theme-color--black;
                        transition: $transition--default;
                    }
                }

            }

            &.has-children{
                & > a{
                    position: relative;
                    &:after{
                        position: static;
                        margin-left: 5px;
                        font-family: $font-awesome-pro;
                        content: '\f107';
                        font-size: 14px;
                        vertical-align: middle;
                        transition: $transition--default;
                    }
                }

                &--multilevel-submenu{
                    position: relative;
                }

                &:hover{
                    .megamenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;
                        &--home-variation{
                            &__item{
                                visibility: visible;
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }

                    }
                    & > .submenu{
                        transform: translateY(0);
                        visibility: visible;
                        opacity: 1;

                    }
                }
                
            }

            &:hover, &.active{
                & > a{
                    &:after{
                        color: $theme-color--black;
                    }
                    & > span{
                        color: $theme-color--black;
                        &:after{
                            width: 100%;
                            left: 0;
                            right: auto;
                        }
                    }
                }
            }
        }
    }

    &--onepage{
        & > ul{
            & > li{
                &.active{
                    & > a{
                        & > span{
                            &:after{
                                display: none;
                            }
                        }           
                    }
                }
            }
        }
    }
    &--text_white {
        & > ul{
            & > li{
                & > a{
                    color: $white;
                    & > span{
                        &:after{
                            background-color: $white;
                        }
                    }
                }
                &.active{
                    & > a{
                        color: $white;
                    }
                }
                
                &:hover, &.active{
                    & > a{
                        &:after{
                            color: $white;
                        }
                        & > span{
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}


/*===================================
    - Multilevel Submenu Css
====================================*/

.submenu {

    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    border-bottom: 3px solid $theme-color--default;
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.3s;
    transition-duration: 0.6s;
    border-radius: 5px;
    visibility: hidden;
    opacity: 0;
    min-width: 270px;
    padding: 25px 0;
    z-index: 9;

    & > li {
        position: relative;
        & > a{
            display: block;
            padding: 11px 30px;
            color: #ababab;
            font-weight: 400;
            transition: 0s;
            & > span{
                position: relative;
                &:after{
                    content: '';
                    width: 0;
                    height: 1px;
                    bottom: 0;
                    position: absolute;
                    left: auto;
                    right: 0;
                    z-index: -1;
                    background-color: $theme-color--default;
                    transition: 0.3s;
                }
            }
            &:hover{
                color: $theme-color--default;
                & > span{
                    &:after{
                        width: 100%;
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
        &:hover {
            & > .submenu{
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
                z-index: 9;

            }
        }
        
        &.active {
            & > a{
                color: $theme-color--default;
            }
        }
        
        &.has-children{
            & > a{
                position: relative;
                transition: $transition--default; 
                &:after{
                    position: absolute;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: $font-awesome-pro;
                    content: '\f105';
                    font-size: 14px;
                    vertical-align: middle;
                    color: #ababab;
                }

                

                &:hover{
                    &:after{
                        color: $theme-color--default;
                    }
                }
            }
            &.active {
                & > a{
                    color: $theme-color--default;
                }
            }
        }
    }

    & .submenu{
        top: -100px;
        left: 100%;
        right: auto;

        &.left{
            right: 100%;
            left: auto;
        }
       
        & .submenu{
            top: 0;
            left: 100%;
            right: auto;
            &.left{
                right: 100%;
                left: auto;
            }
            & .submenu{
                top: 0;
                left: 100%;
                right: auto;
                &.left{
                    right: 100%;
                    left: auto;
                }
                & .submenu{
                    top: 0;
                    left: 100%;
                    right: auto;
                    &.left{
                        right: 100%;
                        left: auto;
                    }
                }
            }
        }
    }
        
}


/*=========================================
    - Mega Menu Css
===========================================*/

.megamenu{
    position: absolute;
    top: 100%;
    left: 0;
    box-shadow: 0 2px 29px rgba(0, 0, 0, 0.05);
    border-bottom: 3px solid $theme-color--default;
    background-color: $white;
    transform: translateY(50px);
    transition: $transition--default;
    transition-delay: 0.3s;
    transition-duration: 0.6s;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    border-radius: 5px;


    &--mega{
        min-width: 980px;
        width: 100%;
        padding: 20px 200px 30px;
        display: flex;
        justify-content: space-around;

        @media #{$laptop-device}{
            padding-left: 50px;
            padding-right: 50px;
        }

        @media #{$laptop-device}{
            min-width: 700px;
        }
        & > li{
            flex-basis: 22%;
            padding-left: 15px;
            padding-right: 15px;
            .page-list-title{
                font-size: 14px;
                margin-bottom: 20px;
                color: $theme-color--black;

            }

            & > ul{
                & > li{
                    & > a{
                        padding: 10px 0;
                        color: #ababab;
                        line-height: 1.2;
                        transition: 0.1s; 
                        &:hover{
                            color: $theme-color--default;
                            span{
                                &:after{
                                    width: 100%;
                                    left: 0;
                                    right: auto;
                                }
                            }
                        }
                        & > span{
                            position: relative;
                            &:after{
                                content: '';
                                width: 0;
                                height: 1px;
                                bottom: 0;
                                position: absolute;
                                left: auto;
                                right: 0;
                                z-index: -1;
                                background-color: $theme-color--default;
                                transition: 0.3s;
                            }
                        }
                    }
                    &.active {
                        & > a{
                            color: $theme-color--default;
                        }
                    }
                }
            }
        }
    }
}

/*=================================
    - Mminicart Wrapper Css
==================================*/

.minicart-wrapper{
    position: relative;
    .minicart-icon{
        color: #6D70A6;
        position: relative;
        text-align: center;
        font-size: 18px;
        line-height: 30px;
        padding-right: 15px;
        & .cart_count{
            position: absolute;
            top: -4px;
            right: 0;
            padding: 0 4px;
            min-width: 15px;
            height: 15px;
            border-radius: 15px;
            color: $white;
            background: $theme-color--default;
            content: attr(data-count);
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
        }
        &:hover{
            color: $theme-color--default;
            transform: scale(1.15, 1.15);
        }
    }
    /* minicart */

    .minicart-box{
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 99999;
        visibility: hidden;
        overflow: auto;
        padding: 30px 20px 27px;
        max-height: 700px;
        width: 360px;
        border-bottom: 2px solid $theme-color--default;
        background-color: #fff;
        opacity: 0;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.12);
        transform: translateY(30px);
        transition: $transition--default;

        &.active{
            transform: translateY(0);
            visibility: visible;
            opacity: 1;
        }
    }

    .minicart-product-wrapper{
        margin-bottom: 30px;
        border-bottom: 1px solid #ddd;  
        .single-minicart-product{
            display: flex;
            margin-bottom: 20px;
            &__image{
                flex-basis: 80px;
                border-radius: 3px;
                img{
                    border-radius: 3px;
                }

            }
            &__content{
                flex-basis: calc(100% - 80px);
                margin-left: 15px;
                position: relative;
                .close-icon{
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-weight: 700;
                    color: #888;
                    
                    i{
                        font-size: 12px;
                    }

                    &:hover{
                        color: $theme-color--black;
                    }
                }

                .title{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 1.2;
                    transition: $transition--default;
                    color: #7e7e7e;
                    &:hover{
                        color: $theme-color--default;
                    }
                }

                .quantity{
                    font-size: 15px;
                    color: #7e7e7e;

                }
            }
        }
    }

    .minicart-calculations{
        font-size: 15px;
        color: #7e7e7e;
        font-weight: 500;
        margin-bottom: 30px;
        .value{
            float: right;
            font-weight: 400;
        }
    }

    .minicart-buttons{
        a{
            min-width: 48%;
            &:nth-child(2){
                float: right;
            }
        }
    }


}

/* search */

.search-icon{
    padding-left: 30px;
    &>a{
        font-size: 20px;
        color: #6D70A6;
        &:hover{
            color: $theme-color--default;
            transform: scale(1.15, 1.15);
        }
    }
    @media #{$laptop-device}{
        padding-left: 25px;
    }
}

/* header button */

.header-button{
    padding-left: 30px;
    padding-right: 0;
    a{
        &.ht-btn{
            @media #{$extra-small-mobile}{
                padding: 0 28px;
            }
        }
    }
    &.button--white {
        padding-left: 20px;
        & > a{
            &.btn{
                color: $theme-color--default;
                @media #{$extra-small-mobile}{
                    padding: 0 28px;
                }
            }
        }
    }
}

/* Mobile Mavigation icon */

.mobile-navigation-icon, .page-open-off-sidebar{
    width: 24px;
    height: 25px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;

    &:hover{
        i{
            background-color: $theme-color--default;
            &:before{
                width: 80%;
                background-color: $theme-color--default;
            }
            &:after{
                background-color: $theme-color--default;
                width: 60%;
            }
        }
    }

    i{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: #6D70A6;
        transition: $transition--default;

        &:before{
            position: absolute;
            bottom: 8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #6D70A6;
            content: "";
            transition: $transition--default;
        }

        &:after{
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #6D70A6;
            content: "";
            transition: $transition--default;
        }
    }
}
.page-open-off-sidebar{
    &:hover{
        i{
            width: 100%;
            &:before{
                width: 100%;
            }
            &:after{
                width: 100%;
            }
        }
    }
    i{
        width: 100%;
        background-color: #333;

        &:before{
            width: 70%;
            background-color: #333;
        }

        &:after{
            width: 70%;
            background-color: #333;
        }
    }
}

.hidden-icons-menu{
    margin-left: 15px;
    a{
        color: #6D70A6;
        font-size: 20px;
        &:hover{
            color: $theme-color--default;
        }
    }
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/

.mobile-menu-overlay , .page-oppen-off-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color--black;
    overflow: auto;
    transition: $transition--default;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    cursor: url(../images/icons/light-close.png) 16 16, pointer;
    visibility: hidden;
    opacity: 0;
    
    &__inner{
        transform: translateX(-100%);
        width: 400px;
        height: 100%;
        cursor: default;
        background-color: #5E61E7;
        background-image: linear-gradient(138deg,#5E61E7 0,#9C7AF2 100%);
        transition: $transition--default;
        overflow-y: auto;

        @media #{$extra-small-mobile}{
            width: 300px;
        }
    }

    &.active{
        .mobile-menu-overlay__inner{
            transform: translateX(0);
        }
        visibility: visible;
        opacity: 1;
    }

    &__header{
        background-color: $white;
        padding: 15px 0;
        .mobile-navigation-close-icon{
            position: relative;
            cursor: pointer;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: inline-block;
            &:before{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(45deg);
                transition: $transition--default;
            }
            &:after{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                transition: $transition--default;
            }
            &:hover{
                color: $theme-color--default;
                &:before, &:after{
                    transform: none;
                }
            }
        }
    }
    &__body{
        
        padding: 20px 40px 100px;
        .offcanvas-navigation{
            & > ul{
                & > li{
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                    & > a{
                        display: block;
                        color: $white;
                        padding-top: 18px;
                        padding-bottom: 18px;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1.5;
                        transition: $transition--default;

                        &:hover{
                            color: $white;
                        }
                    }

                    &:last-child{
                        border-bottom: 0;
                    }

                    &.has-children{
                        position: relative;

                        &.active{
                            .menu-expand{
                                &:before{
                                    content: '\f106';
                                }
                            }
                        }

                       .menu-expand{
                            position: absolute;
                            right: 0;
                            top: 12px;
                            width: 40px;
                            height: 40px;
                            background: rgba(255,255,255,.1);
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            cursor: pointer;
                            transition: $transition--default;

                            &:hover{
                                background: rgba(255,255,255,.2);
                            }

                            &:before{
                                content: '\f107';
                                font-size: 18px;
                                font-family: $font-awesome-pro;

                            }
                       }

                       .sub-menu{
                        padding: 12px 0 14px 10px;
                        border-top: 1px solid rgba(255, 255, 255, 0.15);
                           li{
                            border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                            a{
                                display: block;
                                font-size: 15px;
                                color: rgba(255, 255, 255, 0.7);
                                font-weight: 500;
                                line-height: 1.5;
                                padding: 10px 0;

                                &:hover{
                                    color: $white;
                                }
                            }

                            &:last-child{
                                border-bottom: 0;
                            }
                               &.has-children{
                                   position: relative;

                                   &.active{
                                    .menu-expand{
                                        &:before{
                                            content: "\f106";
                                        }
                                    }
                                }
        
                               .menu-expand{
                                    position: absolute;
                                    right: 0;
                                    top: 6px;
                                    width: 30px;
                                    height: 30px;
                                    background: rgba(255,255,255,.1);
                                    color: #fff;
                                    text-align: center;
                                    line-height: 30px;
                                    cursor: pointer;
                                    transition: $transition--default;
        
                                    &:hover{
                                        background: rgba(255,255,255,.2);
                                    }
        
                                    &:before{
                                        content: '\f107';
                                        font-size: 16px;
                                        font-family: $font-awesome-pro;
                                        font-weight: 500;
        
                                    }
                               }
                               }
                               
                           }

                           .sub-menu{
                                li{
                                    &.has-children{
                                        position: relative;

                                    &.active{
                                        .menu-expand{
                                            &:before{
                                                content: "\f106";
                                            }
                                        }
                                    }
                
                                    .menu-expand{
                                            
                
                                            &:before{
                                                content: '\f107';
                                                font-size: 16px;
                                                font-family: $font-awesome-pro;
                                                font-weight: 500;
                
                                            }
                                        }
                                    }
                                }
                           }
                       }
                    }
                }
            }
            
            /* onepage style */
            &--onepage{
                & > ul{
                    & > li{
                        &.active{
                            & > a{
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
            
            
        }
    }
}

/*======================================
    - offset Sidebar
======================================*/
.page-oppen-off-sidebar {
    right: 0;
    &__inner{
        transform: translateX(100%);
        width: 400px;
        float: right;
        background: $white;
        @media #{$extra-small-mobile}{
            width: 300px;
        }
    }
    &.active{
        .page-oppen-off-sidebar__inner{
            transform: translateX(0%);
        }
    }
    &__header{
        background: $white;
        padding: 15px 35px;
    }
    
    & .off-sidebar-contact-info {
        &--list {
            & .item {
                display: flex;
                margin-bottom: 12px;
                
                & .icon {
                    color: #6d70a6;
                    min-width: calc(1em + 18px);
                    font-size: 16px;
                }
                
                & a {
                    &:hover {
                        color: $theme-color--green;
                    }
                }
            }
        }
        & .off-sidebar-widget__list {
            overflow: hidden;
            & li {
                width: 50%;
                float: left;
                margin-bottom: 8px;
                & a {
                    line-height: 17px;
                }
            }
        }
    }
    
}

/*===============================
    - search overlay
===============================*/


.search-overlay{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color--black;
    overflow: auto;
    transition: $transition--default;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.7);
    cursor: url(../images/icons/light-close.png) 16 16, pointer;
    visibility: hidden;
    opacity: 0;
    
    &__inner{
        transform: translateX(-100%);
        width: 400px;
        height: 100%;
        cursor: default;
        background-color: #5E61E7;
        background-image: linear-gradient(138deg,#5E61E7 0,#9C7AF2 100%);
        transition: $transition--default;
        overflow-y: auto;

        @media #{$extra-small-mobile}{
            width: 300px;
        }
    }

    &.active{
        .search-overlay__inner{
            transform: translateX(0);
        }
        visibility: visible;
        opacity: 1;
    }

    &__header{
        background-color: $white;
        padding: 15px 0;
        .mobile-navigation-close-icon{
            position: relative;
            cursor: pointer;
            height: 40px;
            width: 40px;
            line-height: 40px;
            display: inline-block;
            &:before{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(45deg);
                transition: $transition--default;
            }
            &:after{
                position: absolute;
                top: 23px;
                left: 8px;
                content: '';
                width: 24px;
                height: 3px;
                background: $theme-color--black;
                transform-origin: 50% 50%;
                transform: rotate(-45deg);
                transition: $transition--default;
            }
            &:hover{
                color: $theme-color--default;
                &:before, &:after{
                    transform: none;
                }
            }
        }
    }
    &__body{
        padding: 30px 40px;
    }

    &__form{
        position: relative;
        input{
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
            border-radius: 0;
            padding: 15px 50px 15px 0;
            width: 100%;
            color: $white;

            &::placeholder{
                color: $white;
                transition: $transition--default;
            }
            &[type="text"]:focus{
                color:  $white;
                border-color: $transition--default;
            }
        }

        button{
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: none;
            border: none;
            color: $white;
        }

    }
}

/*=====  End of Header  ======*/