/*=============================================
=             50. Shop Pages Css               =
=============================================*/

.archive-shop-actions-wrap {
    padding-bottom: 23px;
    border-bottom: 1px solid #ededed;
    margin: 0 0 30px;
}
.shop-filter-left {
    & .shop-result-count {
        @media #{$large-mobile}{
            margin-bottom: 10px;
        } 
    }
}
.shop-filter-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.shop-view-switcher {
    font-size: 14px;
    margin: 0 -10px 0 30px;
    display: flex;
    align-items: center;
    & .switcher-item {
        color: #ccc;
        display: block;
        padding: 10px;
        line-height: 1;
        &.active {
            color: #333;
        }
    }
}
.modern-grid-5 {
    display: grid;
    grid-template-columns: repeat( 5,1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    @media #{$desktop-device}{
        grid-template-columns: repeat( 4,1fr);
    }
    @media #{$tablet-device}{
        grid-template-columns: repeat( 3,1fr);
    }
    @media #{$large-mobile}{
        grid-template-columns: repeat( 2,1fr);
    }
    @media #{$small-mobile}{
        grid-template-columns: repeat( 1,1fr);
    }
}
.single-product-wrap {
    & .product-thumbnail {
        position: relative;
        overflow: hidden;
        & a {
            display: block;
            & img {
                width: 100%;
            }
        }
    }
    & .product-hover-image {
        transition: all .6s;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        transform: translateY(10px);
    }
    & .product-thumbnail-box {
        & img {
            transition: all .6s;
            width: 100%;
        }
    }
    & .product-badges {
        position: absolute;
        top: 10px;
        right: 8px;
        z-index: 99;
        display: flex;
        & > span {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin: 0 2px;
            padding: 6px 6px 4px;
            min-height: 0;
            min-width: 56px;
            border-radius: 3px;
            color: #fff;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 1;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        & .hot {
            color: #fff;
            background-color: #d3122a;
        }
        & .onsale {
            color: #fff;
            background-color: #f6b500;
        }
    }

    & .product-actions {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 99;
        display: flex;
        align-items: center;
        color: #fff;
        padding-right: 8px;
        background-color: #9C7AF2;
        background-image: linear-gradient(-129deg,#9C7AF2 0,#5E61E7 100%);
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        & .p-action-btn {
            padding: 0px 8px;
            color: $white;
        }
    }
    
    &:hover {
        & .product-actions {
            transform: none;
            visibility: visible;
            opacity: 1;
        }
        & .product-thumb-image img {
            transform: scale3d(1.1, 1.1, 1.1);
        }
        & .product-hover-image {
            z-index: 3;
            opacity: 1;
            visibility: visible;
            transform: translateY(0);
        }
    }
    
    &.list-style {
        display: flex;
        border-bottom: 1px solid #e4e8f6;
        padding-bottom: 30px;
        margin-bottom: 30px;

        & .product-thumbnail {
            position: relative;
            overflow: hidden;
            width: 260px;
            flex-shrink: 0;
        }
        & .product-info {
            flex-grow: 1;
            padding-left: 34px;
            text-align: left;
        } 
        
        
        @media #{$large-mobile}{
            flex-direction: column;
            & .product-thumbnail {
                width: 100%;
            }
            & .product-info {
                padding-left: 0px;
            } 
        }
        
    }
    
    & .product-cart-btn {
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 20px;
        color: #fff;
        background: none;
        transform: none ;
        line-height: 56px;
        height: 56px;
        font-weight: 500;
        &::before {
            content: '\f290' !important;
            font-size: 15px;
            content: '\f360';
            position: absolute;
            left: 0;
            top: 19px;
            visibility: hidden;
            opacity: 0;
            display: inline-block;
            line-height: 1;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 400;
            transition: $transition--default;
        }
        &:hover{
            padding-left: 35px;
            color: $white;
        }
        &:hover::before {
            left: 15px;
            visibility: visible;
            opacity: 1;
            color: $white;
        }
    }
    
    & .product-info {
        margin-top: 20px;
        text-align: center;
        & .product__title {
            font-weight: 400;
            font-size: 16px;
        }
        & .price-box {
            margin-top: 5px;
            & .old-price {
                font-size: .88em;
                font-weight: 400;
                color: #b6b7d2;
                text-decoration: line-through;
            } 
            & .new-price {
                font-size: 14px;
                font-weight: 500;
                color: #6d70a6;
            } 
        }
    }
    
}

.modal-wrapper.modal {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: -9;
}

.modal-wrapper.modal.show {
  visibility: visible;
  opacity: 1;
  z-index: 99999999;
}


.modal-wrapper {
    display: block !important;
    & .modal-content {
      display: block !important;
    }
    & .modal-dialog {
        max-width: 1170px;
    }
    & .modal-body {
        padding: 40px 40px 40px 40px;  
    }
    
    & .close {
        overflow: visible;
        cursor: pointer;
        background: transparent;
        border: 0;
        width: 44px;
        height: 44px;
        line-height: 44px;
        position: absolute;
        right: 7px;
        top: 0;
        text-decoration: none;
        text-align: center;
        opacity: 0.65;
        padding: 0 0 18px 10px;
        font-style: normal;
        font-size: 28px;
        font-weight: 400;
    }
    
}
.product-details-view-content {
    margin-right: 30px;
    margin-left: 30px;
    @media #{$tablet-device,$large-mobile}{
        margin-right: 0px;
        margin-left: 0px;
    }
    @media #{$large-mobile}{
        margin-top: 25px;
    }
}
.product-list-view {
    & .price-box {
        margin-top: 15px;
        & .old-price {
            font-size: 16px;
            font-weight: 400;
            color: #b6b7d2;
            text-decoration: line-through;
        } 
        & .new-price {
            font-size: 18px;
            line-height: 1.34;
            font-weight: 500;
            color: #6d70a6;
        } 
    }
    
    & .product-list-view-actions {
        margin-top: 28px;
        & .item {
            display: inline-block;
            margin-right: 13px;
            @media #{$small-mobile}{
                margin-top: 15px;
                margin-right: 5px;
            }
            & .p-action-btn {
                border: 1px solid #888;
                line-height: 38px;
                height: 54px;
                padding: 8px 20px;
                display: block;
                border-radius: 5px;
                background-size: 200% auto;
                background-position: left center;
                transition: $transition--default;
                &:hover {
                    color: $white;
                    background-image: $gradient--default;
                    background-position: right center;
                    transform: translateY(-3px);
                    border: 1px solid transparent;
                }
            }
        }
    }
    
    & .quantity-wrap {
        margin: 25px 0 0px 0;
    }
    & .quantity > label {
        display: inline-block;
        margin: 12px 0 0 0;
        min-width: 81px;
        padding-right: 15px;
        font-weight: 500;
        color: #333;
    }
    & .quantity-box {
        position: relative;
        width: 100px;
    }
    & .input-text.qty {
        padding: 3px 15px 3px 10px;
        width: 100%;
        height: 48px;
        font-size: 15px;
        font-weight: 500;
        color: #333;
        text-align: center;
        border: none;
        color: #ababab;
        border-color: #f8f8f8;
        background-color: #f8f8f8;
        border-radius: 5px;
        color: #333;
    }
    
    & .single-product-meta {
        border-top: 1px solid #f3f3f3;
        padding-top: 25px;
        margin-top: 30px;
        font-size: 14px;
        color: #ababab;
        & .meta-item {
            display: flex;
            & h6 {
                font-size: 14px;
                min-width: 100px;
                flex-shrink: 0;
                margin: 0 15px 0 0;
                color: #333;
                font-weight: 400;
            }
        }
        .meta-item ~ .meta-item {
            margin-top: 9px;
        }
    }
    & .single-product-share a {
        padding: 0 7px;
        font-size: 13px;
    }
}
.product-large-slider, .product-nav {
    & .large-img, .nav-thumb {
        & img {
            cursor: pointer;
        }
    } 
    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        padding: 0;
        border: none;
        background: transparent;
        color: $white;
        z-index: 77;
        width: 50px;
        height: 50px;
        line-height: 50px;
        color: #333;
        font-size: 28px;
        background: none;
        border: 0;
        text-align: center;
    }
    & .slick-arrow.slick-next {
        right: 0px;
        left: auto;
    }
    & .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: 0;
        margin-right: auto;
    }
}

.product-nav {
    margin: 10px -15px 0 -15px;
    & .nav-thumb {
        padding: 0 15px;
        border: 1px solid transparent;
    }
}

.product-details-tab .nav {
    justify-content: center;
    border-bottom: 1px solid #ddd;
    & li {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin: 0 55px 0 0;
        padding: 0;
        border: 0;
        border-radius: 0;
        background: transparent;
        &:last-child {margin-right: 0}
        @media #{$large-mobile}{
            margin: 0 20px 0 0;
        }
        
        & a {
            display: block;
            padding: 10px 0;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.5;
            color: #888;
            &::before {
                content: "";
                background: $theme-color--default;
                width: 0%;
                height: 3px;
                position: absolute;
                bottom: 0;
                left: 0;
                opacity: 0;
                -ms-filter: 0;
                -webkit-transition: 0.4s;
                transition: 0.4s;
                margin-bottom: -2px;
            }
            &.active {
                color: #333;
                &::before {
                    visibility: visible;
                    opacity: 1;
                    -ms-filter: 1;
                    width: 100%;
                }
            }
            @media #{$large-mobile}{
                font-size: 20px;
            }
        }
    }
}

.comment-form-rating {
    & label { 
        font-size: 14px;
        font-weight: 500;
        color: #333;
        margin-right: 10px;
    }
    & .star-rating {
        & a {
            display: inline-block;
            color: #ffb805;
            line-height: 1;
            font-size: 14px;
            margin: 0;
        }
    }
}
.modal-backdrop {
    position: inherit;
}