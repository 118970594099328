/*=============================================
=      49. Portfolio Details Pages Css         =
=============================================*/
.portfolio-details-section {
    padding-top: 120px;
    @media #{$tablet-device, $large-mobile}{
        padding-top: 0;
    }
    @media #{$desktop-device}{
        padding-top: 10px;
    }
}
.portfolio-details-one-images {
    max-width: 540px;
}
.portfolio-details-grid-masonry {
    max-width: 720px;
}
.portfolio-details-content {
    & .portfolio-categories {
        margin-bottom: 10px;
        color: #b6b7d2;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}

.portfolio-details-table {
    & table {
        margin: 0 0 1.5em;
        margin-bottom: 1.5em;
        width: 100%;
        display: inline-table;
        overflow: hidden;
    }
    & .label {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 1px;
        color: #333;
        margin-bottom: 12px;
    }
    & td:last-child {
        text-align: right;
        padding-right: 0;
    }
    & td {
        border: 0;
        background: none !important;
        padding: 17px 10px;
        padding-right: 10px;
        text-align: left;
        &:first-child {
            padding-left: 0;
        }
    }
    & tr + tr {
        border-top: 1px solid #ededed;
    }
    
    & .portfolio-details-share a {
        padding: 0 10px;
        font-size: 15px;
        color: #ababab;
    }
}
.portfolio-details-grid-masonry {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @media #{$large-mobile}{
        grid-template-columns: repeat(1, 1fr);
        margin-bottom: 30px;
    }
    @media #{$tablet-device}{
        margin-bottom: 30px;
    }
    & .portfolio-details-grid-image {
        img {
            width: 100%;
        }
    }
}
.portfolio-details-five-images {
    position: relative;
    & .caption-text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-size: 14px;
        padding: 10px;
        border-radius: 0 0 5px 5px;
        text-align: center;
    }
}

.entry-portfolio-return-link {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0 4px 32px rgba(51, 51, 51, 0.07);
    font-size: 18px;
}
.portfolio-nav-links {
    position: relative;
}
.portfolio-nav-links .nav-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    position: relative;
    @media #{$large-mobile}{
        grid-template-columns: auto;
    }
    
    & .next {
        text-align: right;
    }
    & .inner div {
        display: flex;
        align-items: center;
        min-height: 190px;
        padding: 20px 0;
    }
    & .prev img {
        margin-right: 28px;
    }
    & .next img {
        margin-left: 28px;
        order: 2;
    }
    & .next a {
        padding-right: 30px;
    }
    & .prev a {
        padding-left: 30px;
    }
    & .inner h6::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-family: 'Font Awesome 5 Pro';
        font-weight: 300;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 24px;

    }
    & .prev h6::before {
        left: 15px;
        content: '\f104';
    }
    & .next h6::before {
        right: 15px;
        content: '\f105';
    }
}