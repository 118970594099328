/*=====================================
=       53. Preview Page Css          =
======================================*/

.hero__purchase , .downlode-btn {
    & .btn {
        &:hover {
            background: #fff;
            color: $theme-color--default;
        }
    }
}
.header__actions--preview {
    & a {
        font-weight: 400;
        &:hover, &:focus {
            font-weight: 400;
        }
    }
    @media #{$desktop-device,$tablet-device,$large-mobile}{
        flex-basis: 70%;
    }
}
.preview-menu {
    & .navigation-menu > ul > li > a {
        font-weight: 400;
        text-transform: uppercase;
    }
}
.single-preview-item {
    &__thumbnail {
        position: relative;
        margin-bottom: 29px;
        box-shadow: 0 5px 43px rgba(51,51,51,.1);
        border-radius: 5px;
        transition: $transition--default;
        & img {
            border-radius: 8px;
        }
    }
    &__wrap {
        &:hover {
            & .single-preview-item__thumbnail {
                box-shadow: 0 41px 43px rgba(51,51,51,.1);
                transform: translateY(-20px);
            }
        }
    }
}
.preview-staps-way {
    & .payment-process-step__one .payment-process-step__content {
        margin-top: 0px;
        position: relative;
    }
    @media #{$large-mobile}{
        .payment-process-step__media {
            margin-bottom: 30px;
        }
    }
    & .process-step-3 {
        margin-top: -200px;
        margin-left: -30px;
        @media #{$large-mobile}{
            margin-top: 0px;
            margin-bottom: 30px;
        }
    }
    
}
.branding-preview-wrap {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#FFF),color-stop(80%,#F6F5F9));
    background: -moz-linear-gradient(top,#FFF 0,#F6F5F9 80%);
    background: -webkit-linear-gradient(top,#FFF 0,#F6F5F9 80%);
    background: -o-linear-gradient(top,#FFF 0,#F6F5F9 80%);
    background: -ms-linear-gradient(top,#FFF 0,#F6F5F9 80%);
    background: linear-gradient(top,#FFF 0,#F6F5F9 80%);
}

.preview-staps-way {
    background: url(../images/bg/landing-image-04.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom center;
}

.auto-plexible-row .single--slider {
    padding: 30px 0; 
    & > img {
        border-radius: 5px;
        box-shadow: 0 0 30px rgba(51,51,51,.1);
    }
}

.branding-flexible-image-slider-wrap {
    overflow: hidden;
    position: relative;
    & .swiper-nav-button {
        font-size: 70px;
        opacity: 1;
        visibility: visible;
        background: transparent;
        &::before {
            background: none;
        }
    }
    .swiper-nav-button:hover  {
        color: $theme-color--default;
        
    }
    .swiper-button-prev {
        left: -50px;
    }
    .swiper-button-next {
        right: -50px;
    }
    @media #{$laptop-device,$desktop-device,$tablet-device,$large-mobile}{
        .swiper-button-prev {
            left: 0px;
        }
        .swiper-button-next {
            right: 0px;
        }
    }
}
.prv-section-footer-bg {
    background: url(../images/bg/landing-image-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}
.footer-preview-content h6 {
    letter-spacing: 7px;
}