
/*=============================================
=            06. Footer Css           =
=============================================*/

.reveal-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;

    @media #{$desktop-device, $tablet-device, $large-mobile}{
        position: static;
    }
}

.footer-widget-wrapper{
    margin-bottom: -35px;
}
.footer-widget{
    margin-bottom: 30px;
    &__title{
        margin-top: -3px;
        color: $theme-color--black;
    }

    &__list{
        li{
            margin-bottom: 10px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
.app-showcase_footer {
    & .heading {
        font-size: 48px;
        line-height: 1.34;
        font-weight: 300;
        margin-bottom: 15px;
        @media #{$large-mobile}{
            font-size: 32px;
        }
        
    }
    & .sub-title {
        line-height: 1.78;
        font-weight: 400;
    }
    
    & .showcase-background__links img {
        border-radius: 5px;
        margin-right: 10px;
        @media #{$small-mobile}{
            margin-right: 0px;
            margin-bottom: 5px;
        }
    }
}
.app-landing_footer {
    & .heading {
        z-index: 2;
        position: relative;
    }
    & .app-landing-background__links img {
        border-radius: 5px;
        margin-right: 10px;
        @media #{$small-mobile}{
            margin-right: 0px;
            margin-bottom: 5px;
        }
    }
}
.social-group__list {
        
    & .ht-social-networks.tilte-style  {
        .link-text {
            color: #CCCCCC;
            &::before {
                background: rgba(255,255,255,0.2);
            }
            &:hover {
                color: $white;
            }
        }
        & .social-link {
            &::before {
                color: #ccc;
            }
        }
    }
}
.copyright-text{
    display: inline-block;
    @media #{$large-mobile}{
        margin-bottom: 15px;
    }
}

.bg_drak-footer {
    background: #242659;
    border-top: 3px solid #5556ca;
    & .footer-widget{
        &__title{
            color: $white;
        }

        &__list{
            li{
                color: rgba(255,255,255,0.7);
                margin-bottom: 10px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    & .copyright-text{
        color: rgba(255,255,255,0.7);
    }
    & .border-top {
        border-top: 1px solid rgba(237,237,237,0.1) !important;
    }
    & .ht-social-networks.solid-rounded-icon .social-link {
        color: #fff;
    }
    & .tweet__text {
        background-color: #fff;
    }
    & .tweet__heading {
        color: #fff;
    }
    
}
.app-landing_footer-share {
    margin-top: 30px;
    & .ht-social-networks.large-icon .social-link {
        color: $white;
    }
}
.light-logo {
    & img {
        width: 180px;
    }
}
.footer-widget-agency {
    & .footer-widget {
        &:nth-child(1){
            align-items: flex-end;
            display: flex;
            @media #{$tablet-device,$large-mobile}{
                align-items: flex-start;
            }
        }
        &:nth-child(2){
            padding-left: 25px;
            @media #{$tablet-device,$large-mobile}{
                padding-left: 15px;
            }
        }
        &:nth-child(3){
            padding-left: 50px;
            @media #{$tablet-device,$large-mobile}{
                padding-left: 15px;
            }
        }
    }
}
.footer-widget-clinc {
    & .footer-widget {
        &:nth-child(2){
            padding-left: 25px;
            @media #{$tablet-device,$large-mobile}{
                padding-left: 15px;
            }
        }
        &:nth-child(3){
            padding-left: 30px;
            @media #{$tablet-device,$large-mobile}{
                padding-left: 15px;
            }
        }
    }
}

.ebook-footer {
    & .horizontal-list ul li {
        color: #6d70a6;
        &::before {
            content: "";
            position: absolute;
            top: 8px;
            left: -2px;
            content: '';
            height: 16px;
            width: 1px;
            background: #6d70a6;
        }
    }
    & .ht-social-networks.tilte-style  {
        .link-text {
            &::before {
                background: rgba(89,69,230,0.2);
            }
            &:hover {
                color: $theme-color--default;
                &::after {
                    background-color: rgba(89,69,230,0.2);
                }
            }
            
        }
        & .social-link { 
            padding: 10px 14px;
            &:hover {
                & .link-text {
                    &::after {
                        background-color: $theme-color--default;
                    }
                }
            }
        }
    }
}

.body-gradient {
    background: -webkit-gradient(linear,left top,left bottom,color-stop(0%,#F6F5F9),color-stop(60%,#FFF));
    background: -moz-linear-gradient(top,#F6F5F9 0,#FFF 60%);
    background: -webkit-linear-gradient(top,#F6F5F9 0,#FFF 60%);
    background: -o-linear-gradient(top,#F6F5F9 0,#FFF 60%);
    background: -ms-linear-gradient(top,#F6F5F9 0,#FFF 60%);
    background: linear-gradient(top,#F6F5F9 0,#FFF 60%);
}


/*=====  End of footer ======*/



/*=============================================
=            twitter feed            =
=============================================*/

.tweet__text {
	position: relative;
	font-size: 14px;
	border: 1px solid #ededed;
	border-radius: 5px;
	padding: 19px 20px 25px;
	margin-bottom: 25px;
	word-wrap: break-word;
	background-color: rgba(89, 69, 230, 0.05);
}

.tweet__text a {
	color: #38cb89;
}

.tweet__text a:hover {
	color: #5945e6;
}

.tweet__text:after {
	content: '';
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	left: 33px;
	bottom: -10px;
	border-color: transparent #f7fbfe transparent transparent;
	border-width: 10px;
}

.tweet__text:before {
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	left: 30px;
	bottom: -12px;
	border-color: transparent #ededed transparent transparent;
	border-width: 12px;
	content: '';
}

.tweet__info {
	position: relative;
	padding-left: 55px;
}

.tweet__info:before {
	content: '\f099';
	font-size: 18px;
	line-height: 1;
	position: absolute;
	top: 0;
	left: 23px;
	display: block;
	font-family: "Font Awesome 5 Brands";
	color: #5945e6;
}

.tweet__heading {
	font-size: 14px;
	color: #333;
}

.tweet__date {
	font-size: 14px;
	color: #ababab;
}

/*=====  End of twitter feed  ======*/
